import type {
  CognitoIdToken,
  CognitoUserSession,
} from 'amazon-cognito-identity-js'
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
import type { IdentityObject } from '~/stores/identityStore'

(window as any).global = window

export class CognitoService {
  readonly poolData = {
    UserPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID as string,
    ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID as string,
  }

  userPool = new CognitoUserPool(this.poolData)
  cognitoUser?: CognitoUser

  storeIdentityObject(idToken: CognitoIdToken): IdentityObject {
    return {
      token: idToken.getJwtToken(),
      email: idToken.payload.email,
      firstName: idToken.payload.given_name,
      lastName: idToken.payload.family_name,
      props: idToken.payload['custom:user_props'],
    }
  }

  async signUp(email: string, password: string, firstName: string, lastName: string, registrationData?: any) {
    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: email }),
      new CognitoUserAttribute({ Name: 'custom:terms_cond_accepted', Value: '1' }),
      new CognitoUserAttribute({ Name: 'custom:terms_cond_version', Value: '1' }),
      new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
      new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
    ]
    if (registrationData) {
      attributeList.push(
        new CognitoUserAttribute({ Name: 'custom:registration_data', Value: JSON.stringify(registrationData) }),
      )
    }

    return new Promise((resolve, reject) => {
      this.userPool.signUp(email, password, attributeList, [], (err, result) => {
        if (err)
          return reject(err)

        return resolve(result!.user)
      })
    })
  }

  async verifyEmail(email: string, code: string) {
    const user = new CognitoUser({ Username: email, Pool: this.userPool })
    return new Promise((resolve, reject) => {
      user.confirmRegistration(code, true, (err, result) => {
        if (err)
          return reject(err)

        return resolve(result.user)
      })
    })
  }

  async getSession(): Promise<{
    token: string
    email: string
    firstName: string
    lastName: string
    props: any
  } | null> {
    this.cognitoUser = this.userPool.getCurrentUser() ?? undefined
    return new Promise((resolve, reject) => {
      if (this.cognitoUser) {
        this.cognitoUser.getSession((error: Error | null, session: CognitoUserSession) => {
          if (error) {
            reject(error)
          }
          else {
            const idToken = session.getIdToken()
            if (idToken.getExpiration() < new Date().getTime()) {
              resolve(this.storeIdentityObject(idToken))
            }
            else {
              this.cognitoUser?.refreshSession(session.getRefreshToken(), (err, result) => {
                if (err) {
                  reject(err)
                }
                else {
                  const idToken = result.getIdToken()
                  resolve(this.storeIdentityObject(idToken))
                }
              })
            }
          }
        })
      }
      else {
        resolve(null)
      }
    })
  }

  async logIn(username: string, password: string): Promise<IdentityObject> {
    const authenticationData = {
      Username: username,
      Password: password,
    }

    const authenticationDetails = new AuthenticationDetails(authenticationData)

    const userData = {
      Username: authenticationData.Username,
      Pool: this.userPool,
    }

    this.cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      this.cognitoUser!.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const idToken = result.getIdToken()
          resolve(this.storeIdentityObject(idToken))
        },

        onFailure: (err) => {
          let message = ''
          if (err.message === 'Password attempts exceeded')
            message = 'U heeft te vaak een verkeerd wachtwoord ingevoerd. Probeer het later nog eens.'
          else
            message = 'De combinatie van e-mailadres en wachtwoord is niet correct.'

          reject(message)
        },
      })
    })
  }

  async logout(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.cognitoUser) {
        this.cognitoUser.signOut(() => {
          return resolve(true)
        })
      }
      else {
        return resolve(true)
      }
    })
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.cognitoUser) {
        this.cognitoUser.changePassword(oldPassword, newPassword, (err) => {
          if (err)
            reject(err)
          else
            resolve()
        })
      }
      else {
        reject(new Error('No user logged in.'))
      }
    })
  }

  async forgotPassword(email: string) {
    const user = new CognitoUser({ Username: email, Pool: this.userPool })
    return new Promise((resolve, reject) => {
      user.forgotPassword({
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  }

  async confirmPassword(email: string, verificationCode: string, newPassword: string): Promise<void> {
    const user = new CognitoUser({ Username: email, Pool: this.userPool })

    return new Promise((resolve, reject) => {
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          resolve()
        },
        onFailure(err) {
          reject(err)
        },
      })
    })
  }
}

export default new CognitoService()
